import React, {useRef} from 'react';
import './join.css'
import emailjs from '@emailjs/browser';

const Join = () => {

     const form = useRef()
     const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_62gva02', 'template_fo6hulc', form.current, 'U9RUQo3rW8OqwBrIm')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
      
    return <div className="join" id='join'>
        <div className="join-left">
            <div className="join-left-text">
                <hr />
            <div>
                <span className='stroke-text'>READY TO </span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY </span>
                <span className='stroke-text'>WITH US?</span>
            </div>
            </div>
        </div>
        <div className="join-right">
            <form ref={form} className="email" onSubmit={sendEmail}>
              <input type="email" name='to_name' placeholder='Enter your email address' />
              <button className="btn join-btn">Join Now</button>
            </form>
        </div>
    </div>;
}



export default Join;