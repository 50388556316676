import React from 'react';
import './plans.css'
import {plansData} from '../../data/plansData'

const Plans = () => {
    return <div className="plans" id='plans'>
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
        <div className="plans-heading">
            <span className="stroke-text">READY TO START</span>
            <span>YOUR JOURNEY</span>
            <span className="stroke-text">NOW WITHUS</span>
        </div>
        <div className="plans-card">
          {plansData.map((plan, i)=>(
            <div className="plan-data" key={i}>
                {plan.icon}
                <span className='plan-name'>{plan.name}</span>
                <span className='plan-price'>$ {plan.price}</span>
                <div className="features">
                    {plan.features.map((feature,i)=>(
                        <div className="feature">
                            <span key={i}>{feature}</span>
                        </div>
                    ))}
                </div>
                <div className='plans-seemore'>
                    <span>See more benefits</span>
                </div>
                <button className="btn">Join Now</button>
            </div>
          ))}
        </div>
    </div>;
}


export default Plans;