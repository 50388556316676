import React from 'react';
import Header from '../Header/Header';
import './hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter'

const Hero = () => {

    const transition = {type:"tween", duration:3}
    const mobile = window.innerWidth <= 768? true: false
    return <div className='hero' id='hero'>
        <div className="blur hero-blur"></div>
         <div className="hero-left">
                <Header/>
                <div className="the-best-ad">
                    <motion.div
                    initial={{left: mobile?"165px":"238px"}}
                    whileInView={{left:"8px"}}
                    transition={{...transition}}>
                    </motion.div>
                    <span>The best fitness club in the town</span>
                </div>
                <div className='hero-heading'>
                    <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal body</span>
                    </div>
                    <div className="hero-heading-desc">
                        <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
                    </div>
                </div>
                <div className="hero-heading-figures">
                    <div>
                        <span><NumberCounter start={100} end={140} delay="4" preFix="+"/></span>
                        <span>Expert coaches</span>
                    </div>
                    <div>
                        <span><NumberCounter start={800} end={978} delay="4" preFix="+"/></span>
                        <span>Members joined</span>
                    </div>
                    <div>
                        <span><NumberCounter start={0} end={50} delay="4" preFix="+"/></span>
                        <span>Fitness programs</span>
                    </div>
                </div>
                <div className="hero-buttons">
                    <button className='btn'>Get started</button>
                    <button className='btn'>Learn more</button>
                </div>
            </div>
            <div className="hero-right">
                <button className='btn hero-right-btn'>Join now</button>
                <motion.div className="heart-rate"
                initial={{right:"-1rem"}}
                whileInView={{right:"4rem"}}
                transition={transition}>
                    <img src={heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                <div className="hero-images">
                    <img src={hero_image} alt="" className="hero-image" />
                    <motion.img initial={{right:"11rem"}} whileInView={{right:"20rem"}} transition={transition} src={hero_image_back} alt="" className="hero-image-back" />
                </div>
                <motion.div className="calories"
                initial={{right:"37rem"}}
                whileInView={{right:"28rem"}}
                transition={transition}>
                    <img className='calories-img' src={calories} alt="" />
                    <div> 
                    <span>Calories burned</span>
                    <span>220 kcal</span>
                    </div>
                </motion.div>
            </div> 
            
    </div>;
}



export default Hero;

