import React from 'react';
import './progrsma.css'
import {programsData} from '../../data/programsData'


const Programs = () => {
    return <div className="programs" id="programs">
        <div className="programs-heading"> 
        <span className='stroke-text'>Explore our</span>
        <span>Programs</span>
        <span className='stroke-text'>To shape you</span>
        </div>
        <div className="programs-categories">
            {programsData.map((program)=>(
               <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="proggram-button">
                    <span>Join Now</span>
                    <img src="/src/assets/rightarrow.png" alt="" />
                </div>
               </div>
            ))}
        </div>
    </div>
}



export default Programs;