import React, {useState} from 'react';
import './testimonials.css'
import {testimonialsData} from '../../data/testimonialsData'
import leftArrow from '../../assets/leftArrow.png'
import rightarrow from '../../assets/rightarrow.png'
import { motion } from 'framer-motion';

const Testimonials = () => {
    const [selected, setSelected] = useState(0)
    const tlength = testimonialsData.length
    const transition = {type:"tween", duration:3}

    return <div className='testimonials'>
        <div className="testimonials-left">
            <span>TESTIMONIALS</span>
            <span className='stroke-text'>WHAT THEY</span>
            <span>SAY ABOUT US</span>
            <motion.span key={selected} initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}} exit={{opacity:0, x:-100}} transition={transition}>{testimonialsData[selected].review}</motion.span>
            <span>
                <span style={{color: "var(--orange)"}}>{testimonialsData[selected].name} </span>
                - {testimonialsData[selected].status}
            </span>
        </div>
        <div className="testimonials-right">
            <motion.div
            initial={{opacity:0, x:-100}}
            whileInView={{opacity:1, x:0}}
            transition={{...transition, duration:2}}></motion.div>
            <motion.div
            initial={{opacity:0, x:100}}
            whileInView={{opacity:1, x:0}}
            transition={{...transition, duration:2}}></motion.div>
            <motion.img key={selected} initial={{opacity:0, x:100}}
            whileInView={{opacity:1, x:0}} exit={{opacity:0, x:-100}} transition={transition} src={testimonialsData[selected].image} alt="" />
            <div className="testimonials-right-arrow">
            <img onClick={()=>{selected===0?setSelected(tlength -1): setSelected((prev)=>prev -1)}} src={leftArrow} alt="" />
            <img onClick={()=>{selected===(tlength -1)?setSelected(0): setSelected((prev)=>prev +1)}} src={rightarrow} alt="" />
            </div>
        </div>
    </div>;
}


export default Testimonials;